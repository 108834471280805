'use client';
import { createRef, useEffect, useState } from 'react';
import Link from 'next/link';
import { testsCategoryUrl } from 'src/services/route-utils';

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1'.split('');

export default function TestsLetters() {
    const [indicators, setIndicators] = useState([]);
    const [activeIndicator, setActiveIndicator] = useState(0);
    const scrollDivRef = createRef<HTMLDivElement>();

    useEffect(() => {
        const div = scrollDivRef.current;
        if (!div) return;

        const getActiveIndicator = () => {
            setActiveIndicator(Math.round(div.scrollLeft / div.clientWidth));
        };

        const computeIndicators = () => {
            setIndicators([].concat.apply([], Array(Math.floor(div.scrollWidth / div.clientWidth))).map((_, i) => i));
            getActiveIndicator();
        };

        window.addEventListener('resize', computeIndicators);
        div.addEventListener('scroll', getActiveIndicator);
        computeIndicators();

        return () => {
            window.removeEventListener('resize', computeIndicators);
        };
    }, []);

    return (
        <>
            <div className="scrollbar-hide container mx-auto overflow-scroll px-4 py-6" ref={scrollDivRef}>
                <ul className="flex flex-nowrap gap-4 text-center md:flex-wrap">
                    {characters.map((c) => (
                        <li key={c} className="basis-1/7 text-xl">
                            <Link
                                href={testsCategoryUrl(null, { l: c })}
                                className="text-dark-green hover:bg-secondary inline-flex h-12 w-12 items-center justify-center rounded-full bg-white text-center">
                                {c === '1' ? '#' : c}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="container mx-auto mb-4 flex h-2 gap-2 px-4 md:hidden">
                {indicators.map((i) => (
                    <div
                        className={`${
                            i === activeIndicator ? 'bg-white' : 'bg-light-green/50'
                        } h-2 w-2 rounded-full transition-all duration-300`}
                        key={i}
                    />
                ))}
            </div>
        </>
    );
}
